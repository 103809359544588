import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import ErrorPage from "./views/error-page/Main.vue";

// import { PolicyRule } from "@cloudfun/core";

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: "dashboard", component: () => import("./views/dashboard/Main.vue") },
  {
    path: "configuration",
    component: () => import("./views/configuration/Main.vue"),
    meta: { rule: "Configuration" }
  },
  // {
  //   path: "user-wizard",
  //   component: () => import("./views/user-wizard/Main.vue"),
  //   meta: { rule: new PolicyRule("Role").and("User") }
  // },
  {
    path: "role",
    component: () => import("./views/role/Main.vue"),
    meta: { rule: "Role" }
  },
  {
    path: "user",
    component: () => import("./views/user/Main.vue"),
    meta: { rule: "User" }
  },
  {
    path: "action-log",
    component: () => import("./views/action-log/Main.vue"),
    meta: { rule: "ActionLog" }
  },
  {
    path: "banner",
    component: () => import("./views/banner/Main.vue"),
    meta: { rule: "Banner" }
  },
  {
    path: "warehouse",
    component: () => import("./views/warehouse/Main.vue"),
    meta: { rule: "Warehouse" }
  },
  {
    path: "supplier",
    component: () => import("./views/supplier/Main.vue"),
    meta: { rule: "Supplier" }
  },
  {
    path: "template/:name",
    component: () => import("./views/template/Main.vue"),
    meta: { rule: "Template" }
  },
  {
    path: "country",
    component: () => import("./views/country/Main.vue"),
    meta: { rule: "Country" }
  },
  {
    path: "category",
    component: () => import("./views/category/Main.vue"),
    meta: { rule: "Category" }
  },
  {
    path: "brand",
    component: () => import("./views/brand/Main.vue"),
    meta: { rule: "Brand" }
  },
  {
    path: "tag",
    component: () => import("./views/tag/Main.vue"),
    meta: { rule: "Tag" }
  },
  {
    path: "news-category",
    component: () => import("./views/news-category/Main.vue"),
    meta: { rule: "NewsCategory" }
  },
  {
    path: "news",
    component: () => import("./views/news/Main.vue"),
    meta: { rule: "News" }
  },
  {
    path: "member",
    component: () => import("./views/member/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "member-account-books",
    component: () => import("./views/member-account-books/Main.vue"),
    meta: { rule: "Member" }
  },
  {
    path: "member-grade",
    component: () => import("./views/member/Grade.vue"),
    meta: { rule: "MemberGrade" }
  },
  {
    path: "blacklist",
    component: () => import("./views/member/Blacklist.vue"),
    meta: { rule: "Ban" }
  },
  {
    path: "customer-contact",
    component: () => import("./views/customer-contact/Main.vue"),
    meta: { rule: "CustomerContact" }
  },
  {
    path: "product",
    component: () => import("./views/product/Main.vue"),
    meta: { rule: "Product" }
  },
  {
    path: "order",
    component: () => import("./views/order/Main.vue"),
    meta: { rule: "Order" }
  },
  {
    path: "shopping-cart",
    component: () => import("./views/shopping-cart/Main.vue")
  },
  {
    path: "activity",
    component: () => import("./views/activity/Main.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "activity-product",
    component: () => import("./views/activity/Product.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "activity-rule",
    component: () => import("./views/activity/Rule.vue"),
    meta: { rule: "Activity" }
  },
  {
    path: "coupon",
    component: () => import("./views/coupon/Main.vue"),
    meta: { rule: "Coupon" }
  },
  {
    path: "payment",
    component: () => import("./views/payment/Main.vue"),
    meta: { rule: "Payment" }
  },
  // { path: "report", component: () => import("./views/report/Main.vue") },
  {
    path: "purchase-order",
    component: () => import("./views/purchase-order/Main.vue"),
    meta: { rule: "PurchaseOrder" }
  },
  {
    path: "purchase-bill",
    component: () => import("./views/purchase-bill/Main.vue"),
    meta: { rule: "PurchaseBill" }
  },
  {
    path: "adjust-inventory-bill",
    component: () => import("./views/adjust-inventory-bill/Main.vue"),
    meta: { rule: "AdjustmentMemo" }
  },
  {
    path: "requisition-memo",
    component: () => import("./views/requisition-memo/Main.vue"),
    meta: { rule: "RequisitionMemo" }
  },
  {
    path: "inventory",
    component: () => import("./views/inventory/Main.vue"),
    meta: { rule: "Inventory" }
  },
  {
    path: "inventory-record",
    component: () => import("./views/inventory-record/Main.vue"),
    meta: { rule: "Inventory" }
  },
  {
    path: "faq-category",
    component: () => import("./views/faq-category/Main.vue"),
    meta: { rule: "Faq" }
  },
  {
    path: "faq",
    component: () => import("./views/faq/Main.vue"),
    meta: { rule: "Faq" }
  },
  { path: "attribute", component: () => import("./views/attribute/Main.vue") },
  {
    path: "brandService",
    component: () => import("./views/brand/Service.vue")
  },
  {
    path: "email-template",
    component: () => import("./views/template/email/Main.vue")
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/side-menu/Main.vue")
  },
  {
    path: "/simple-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/simple-menu/Main.vue")
  },
  {
    path: "/top-menu",
    redirect: "dashboard",
    children: viewRotes,
    component: () => import("./layouts/top-menu/Main.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login/Main.vue")
  },
  { path: "/error-page", name: "error-page", component: ErrorPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage }
];

export default createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});
